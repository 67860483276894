<template lang="pug">
  b-card.manager-machines-form.card-white.card-no-gutters.shadow(no-body)
    wc-card-tabs(v-if="action === 'update'" :tabs='tabs')
    b-card-header.bg-transparent.border-0(v-else)
      h5.m-0
        | {{ $t('activerecord.models.organization.one') }}
    b-form.form-wc.form-organization-form.mt-sm-3(
      @submit.stop.prevent='formSubmit'
      @input.stop.prevent='formChange'
      @reset.stop.prevent='formReset')
      b-card-body

        wc-forms-alerts(v-if='formShowErrors' :errors='form.errors.base')

        transition(name='fade')
          fieldset(v-if='formShow' :disabled='formDisable')
            b-form-group#form-machine-organization(
              :label="$t('activerecord.models.organization.one')"
              label-for='machine-organization_id'
              label-cols-md='2'
              label-align-md='right')
              multiselect#machine-organization_id.form-input-first.shadow-sm.custom-select.is-required(
                name='organization'
                v-model='machineOrganizationIdSelected'
                :options='form.selects.machineOrganizationIdOptions'
                :class='formValidationClass($v.form.data.attributes.organization_id)'
                :close-on-select='true'
                :clear-on-select='false'
                :placeholder="$t('machine.placeholders.organization')"
                label='label'
                track-by='value'
                :internal-search='false'
                :selectLabel="$t('shared.forms.multiselect.select_label')"
                :selectedLabel="$t('shared.forms.multiselect.selected_label')"
                :deselectLabel="$t('shared.forms.multiselect.deselect_label')"
                :limitText="count => $t('shared.forms.multiselect.limit_text', { count: count })"
                :loading='form.selects.machineOrganizationIdLoading'
                @search-change='searchOrganization'
                @input='formSelectOption')
                span(slot='noResult')
                  | {{ $t('shared.forms.multiselect.no_result') }}
                span(slot='noOptions')
                  | {{ $t('shared.forms.multiselect.no_options_search') }}
              wc-forms-if#machine-organization_id-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.organization_id'
                :remote="formRemoteInvalidFeedback('organization')"
                :validators='{ required: {} }')

            b-form-group#form-machine-model(
              :label="$t('activerecord.models.machine/model.one')"
              label-for='machine-model_id'
              label-cols-md='2'
              label-align-md='right')
              multiselect#machine-model_id.form-input-middle.shadow-sm.custom-select.is-required(
                name='model'
                v-model='machineModelIdSelected'
                :options='machineModelIdOptions'
                :class='formValidationClass($v.form.data.attributes.model_id)'
                :close-on-select='true'
                :clear-on-select='false'
                :placeholder="$t('machine.placeholders.model')"
                label='label'
                track-by='value'
                :selectLabel="$t('shared.forms.multiselect.select_label')"
                :selectedLabel="$t('shared.forms.multiselect.selected_label')"
                :deselectLabel="$t('shared.forms.multiselect.deselect_label')"
                :limitText="count => $t('shared.forms.multiselect.limit_text', { count: count })"
                @input='formSelectOption')
                span(slot='noResult')
                  | {{ $t('shared.forms.multiselect.no_result') }}
                span(slot='noOptions')
                  | {{ $t('shared.forms.multiselect.no_options') }}
              wc-forms-if#machine-model_id-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.model_id'
                :remote="formRemoteInvalidFeedback('model')"
                :validators='{ required: {} }')

            b-form-group#form-machine-serial(
              :label="$t('activerecord.attributes.machine.serial')"
              label-for='machine-serial'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#machine-serial.shadow-sm.is-required(
                name='serial'
                type='text'
                autocomplete='serial'
                :placeholder="$t('machine.placeholders.serial')"
                :state='formValidationState($v.form.data.attributes.serial)'
                :class="action === 'create' ? 'form-input-last' : 'form-input-middle'"
                @focus.native='formChange'
                v-model='$v.form.data.attributes.serial.$model'
                aria-describedby='machine-serial-feedback'
                trim)
              wc-forms-if#machine-serial-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.serial'
                :remote="formRemoteInvalidFeedback('serial')"
                :validators='{ required: {}, regExp: {}, minLength: {}, maxLength: {}, takenOrNotAllowed: {} }')

            b-form-group#form-machine-token(
              v-if="action === 'update'"
              :label="$t('activerecord.attributes.machine.token')"
              label-for='machine-token'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#machine-token.form-input-last.shadow-sm(
                name='token'
                type='text'
                v-model='form.data.attributes.token'
                :readonly='true'
                trim)

      b-card-footer.border-0.bg-white
        wc-forms-buttons#form-organization-buttons(:disabled='formDisable || $v.form.data.$invalid')

    loading(:active.sync='isLoading' :can-cancel-esc='true' :on-cancel='onCancel')
</template>

<script>
import Tabs from '@views/container/manager/machines/shared/tabs'
import WcFormsButtons from '@components/shared/forms/WcFormsButtons'
import apiMachines from '@services/api/manager/machines'
import { Form, mapSelects } from '@common/form'
import { regExp } from '@common/form/validations'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'manager-organizations-form',
  mixins: [Form, Tabs],
  components: {
    WcFormsButtons,
  },
  computed: {
    ...mapSelects([
      {
        name: 'machineOrganizationId',
        default: 'relationships.organization.data.id',
        attribute: 'attributes.organization_id',
        included: { type: 'organization', key: 'id', value: 'attributes.name' },
      },
      {
        name: 'machineModelId',
        default: 'relationships.model.data.id',
        attribute: 'attributes.model_id',
        collection: { data: 'models.data', key: 'id', value: 'attributes.name' },
      },
    ]),
    machineUpdateQuery() {
      return this.action === 'update' ? { id: this.machine_id } : {}
    },
    apiParams() {
      return {
        get: [{ id: this.machine_id || 'new' }, {}],
        create: [
          {},
          { [this.apiModel]: this.formSubmitData(this.$getDeep(this.form, 'data.attributes')) },
        ],
        update: [
          { id: this.machine_id },
          { [this.apiModel]: this.formSubmitData(this.$getDeep(this.form, 'data.attributes')) },
        ],
        machineOrganizationIdSearch: [
          { id: this.machine_id },
          { term: this.form.selects.machineOrganizationIdTerm },
        ],
        uniqueSerial: [
          {},
          {
            ...this.machineUpdateQuery,
            term: this.$getDeep(this.form, 'data.attributes.serial'),
          },
          { responseErrorRaw: true },
        ],
      }
    },
  },
  methods: {
    searchOrganization(term, id) {
      this.formMultiselectSearch(term, id, 'searchOrganization', 'id_humanized')
    },
    apiCallback(callback) {
      if (callback === 'submit-success') this.$emit('machine-changed')
    },
  },
  validations() {
    return {
      form: {
        data: {
          attributes: {
            organization_id: {
              required,
              remote: () => this.formRemoteValid('organization'),
            },
            model_id: {
              required,
              remote: () => this.formRemoteValid('model'),
            },
            serial: {
              required,
              regExp: regExp(this.$wc.conf.regex.serial_browser),
              minLength: minLength(this.$wc.conf.limits.min_serial),
              maxLength: maxLength(this.$wc.conf.limits.max_serial),
              takenOrNotAllowed: async () => {
                /* Validate only if. */
                if (
                  this.validateIf('serial', ['required', 'regExp', 'minLength', 'maxLength'], {
                    if: 'invalid',
                  })
                )
                  return true

                /* Validate remote. */
                return await new Promise((resolve) => {
                  clearTimeout(this.form.timer.uniqueSerial)
                  this.form.timer.uniqueSerial = setTimeout(resolve, 600)
                }).then(() => {
                  if (this.apiRequest && this.apiRequest.source) this.apiRequest.source.cancel()
                  this.apiRequest = this.formRequest('uniqueSerial')
                  return this.apiRequest.promise // eslint-disable-line promise/no-nesting
                    .then(() => {
                      return true
                    })
                    .catch((error) => {
                      return error.response && error.response.status === 422 ? false : true
                    })
                })
              },
              remote: () => this.formRemoteValid('serial'),
            },
          },
        },
      },
    }
  },
  watch: {
    'form.data.attributes.serial'(value) {
      if (this.$v.form.data.attributes.serial.$dirty) {
        this.$v.form.data.attributes.serial.$model = value.toUpperCase()
      }
    },
  },
  data() {
    return {
      apiBase: apiMachines,
      apiModel: 'machine',
      machine_id: this.$route.params.machine_id,
      asyncDataSelects: ['machineOrganizationId'],
      skipFormData: ['token', 'policies'],
    }
  },
}
</script>
