import WcCardTabs from '@components/shared/WcCardTabs'

const MachineTabs = {
  components: {
    WcCardTabs,
  },

  computed: {
    tabs() {
      return [
        {
          name: this.$t('activerecord.models.machine.one'),
          route: { name: 'manager-machines-edit', params: { id: this.machine_id } },
        },
        {
          name: this.$t('activerecord.models.machine/property.one'),
          route: {
            name: 'manager-machines-property-edit',
            params: { machine_id: this.machine_id },
          },
        },
        {
          name: this.$t('machine.telemetry.title'),
          route: {
            name: 'manager-machines-telemetry',
            params: { machine_id: this.machine_id },
          },
        },
        {
          name: this.$t('machine.analytics.title'),
          route: {
            name: 'manager-machines-analytics',
            params: { machine_id: this.machine_id },
          },
        },
        {
          name: this.$t('activerecord.models.firmware.one'),
          route: {
            name: 'manager-machines-firmware-edit',
            params: { machine_id: this.machine_id },
          },
        },
        {
          name: this.$t('activerecord.models.machine/report.other'),
          route: {
            name: 'manager-machines-reports',
            params: { machine_id: this.machine_id },
          },
        },
      ]
    },
  },
}

export default MachineTabs
